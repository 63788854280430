export default class Modal {
    constructor(closeCallback) {
        this.el = $(`<div class="modal">
            <div class="modal-content">
                <div class="modal-content__wrapper"></div>
                <button class="action-close" title="schließen">schließen</button>
                <div class="modal-progress"></div>
            </div>
        </div>`);
        this.contentBox = this.el.find('.modal-content');
        this.contentWrapper = this.el.find('.modal-content__wrapper');
        this.closeButton = this.el.find('.action-close');
        this.progressOverlay = this.el.find('.modal-progress');

        this.el.on('click', () => this.close());
        this.closeButton.on('click', () => this.close());
        this.closeCallback = closeCallback;
        this.contentBox.on('click', function (e) {
            e.stopPropagation();
        })
    }

    show(content, title) {
        this.content(content, title);

        this.el.hide().appendTo(document.body).fadeIn();
        return this;
        /*$('html, body').css('overflow', 'hidden');*/
    }

    open(content, title) { this.show(content, title) }

    content(content, title) {
        this.contentWrapper
            .html('')
            .append(title ? '<h1>' + title + '</h1>' : '')
            .append(content);
    }

    close() {
        this.el.fadeOut(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
            this.el.remove();
            /*$('html, body').css('overflow', '');*/
        });
    }

    hide() { this.close() }

    progress(p = true) {
        this.progressOverlay.toggleClass('modal-progress--show', p);
    }
} 