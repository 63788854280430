export function findPasswordToggle() {
    $('.toggle-show-password').each((idx, el) => {
        toggleShowPassword(el);
    })
}

export function toggleShowPassword(elem) {
    const $elem = $(elem);
    const $wrapper = $('<div class="password-show-toggle__wrapper"></div>');
    $wrapper.insertBefore($elem);
    $elem.appendTo($wrapper);
    const $toggle = $('<div class="password-show-toggle" title="Passwort anzeigen"><span class="icon icon-eye"></span></div>').appendTo($wrapper);
    $toggle.on('click', ev => {
        ev.stopPropagation();
        ev.preventDefault();

        $elem.attr('type', $elem.attr('type') === 'text' ? 'password' : 'text');

        return false;
    })
}