export default class CookiePopup {
    constructor() {
        $('#cookie-ok').on('click', function () {
            var date = new Date();
            date.setFullYear(date.getFullYear() + 1);
            document.cookie = 'cookies_accepted=true; path=/; expires=' + date.toUTCString();
            $('#cookie-info').addClass('accepted')
            setTimeout(function () {
                window.location.reload();
            }, 400)
            return false;
        })
    }
}