import Masonry from 'masonry-layout'

import { loadImage } from '../image'
import Modal from '../modal'

export function initNewsItems(parent) {
    parent = parent || $(document.body);
    parent.find('.show-images').on('click', function () {
        var context = $(this).parents('.news-item');
        var images = context.find('.images');
        if (images.length || context.data('loading')) {
            if (context.data('loading')) {
                context.data('loading').abort();
                context.data('loading', null);
            }
            images.css('height', 0).on('transitionend', function () {
                if ($(this).height() > 0) { return; }
                $(this).remove();
            });
        } else {
            var id = context.attr('data-id');
            var loading = $.get('/Media/ForNews/' + id);
            images = $('<div class="images layout-images"></div>');
            context.append(images);
            const lt = setTimeout(() => {
                images.addClass('loading');
            }, 16);
            loading.then(function (result) {
                images.append($(result).html());
                clearTimeout(lt);
                images.removeClass('loading');
                
                var elem = $('[data-layout="masonry"]', images)[0];
                var mason = new Masonry(elem, {
                    percentPosition: true,
                    columnWidth: '.grid-sizer',
                    gutter: '.gutter-sizer',
                    itemSelector: '.image-item'
                });
                elem.masonry = mason;
                loadImage(images.find('img'), function () {
                    images.css('height', (elem.offsetHeight + 18) + 'px');
                });
                if (window.gtag) { window.gtag('event', 'show_images', { 'id': id }); }
 
            });
            context.data('loading', loading);
        }
        return false;
    });

    parent.find('a.subscribe').on('click touchstart', function () {
        var $this = $(this);

        if ($this.hasClass('disabled') || $this.attr('href').indexOf('#') < 0) return true;

        $this.addClass('clicked');

        var nid = $this.parents('[data-newsroom-id]').attr('data-newsroom-id');
        if ($this.hasClass('subscribed')) {
            //remove subscription
            $('[data-newsroom-id="' + nid + '"] .subscribe')
                .removeClass('subscribed')
                .attr('title', 'Unternehemen folgen');
            $.post('/User/Unsubscribe/' + nid).then(function (result) {
                console.log(result);
            });
        } else {
            //subscribe news
            $('[data-newsroom-id="' + nid + '"] .subscribe')
                .addClass('subscribed')
                .attr('title', 'Unternehmen entfolgen');
            $.post('/User/Subscribe/' + nid).then(function (result) {
                if (result.error) {
                    new Modal().show(result.msg);
                    $('[data-newsroom-id="' + nid + '"] .subscribe')
                        .removeClass('subscribed')
                        .attr('title', 'Unternehmen folgen');
                }
            });
        }
        return false;
    });

    parent.find('a.subscribe').on('mouseleave', function () {
        $(this).removeClass('clicked')
    });

    parent.find('a.bookmark').on('click touchstart', function () {
        var $this = $(this);

        if ($this.hasClass('disabled') || $this.attr('href').indexOf('#') < 0) return true;

        $this.addClass('clicked');

        var id = $this.parents('[data-id]').attr('data-id');
        if ($this.hasClass('bookmarked')) {
            //unbookmark news
            $this.removeClass('bookmarked').attr('title', 'Merken');
            $.post('/User/Unbookmark/' + id).then(function (result) {
                console.log(result);
            });
        } else {
            //bookmark news
            $this.addClass('bookmarked').attr('title', 'Markierung entfernen');
            $.post('/User/Bookmark/' + id).then(function (result) {
                console.log(result);
            });
        }
        return false;
    });

    parent.find('a.bookmark').on('mouseleave', function () {
        $(this).removeClass('clicked')
    });

    parent.find('a.overlink').on('click', e => {
        const $e = $(e.currentTarget);

        if ($e.parents('.collapsed').length && !$e.parents('.news-item.open').length) {
            $e.parents('.news-item').addClass('open').siblings('.open').removeClass('open');
            return false;
        }
    });
}