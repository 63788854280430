/**
* Dropdown helper functions
*/

export default class Dropdown {
    constructor(elem) {
        if (elem._dropdown) return elem._dropdown;
        this.elem = $(elem);
        elem._dropdown = this;
    }

    refresh() {
        const { elem } = this;
        const value = elem.find(':checked').val() || elem.attr('data-value');
        const label = elem.find('.label').first();
        const format = elem.data('formatValue') || (v => v);
        if (value === elem.attr('data-default')) {
            elem.removeClass('selected');
            label.html(label.attr('data-default'));
        } else {
            elem.addClass('selected').find('.label').first().html(format(value));
        }
    }
}