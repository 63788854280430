import { loadImage } from '../image'

export function initMediaItems(parent) {
    parent = parent || $(document.body);
    parent.find('.image-item').addBack('.image-item').find('a').on('click', function () {
        var target = $(this).parents('li');

        if (target.hasClass('open')) {
            target.removeClass('open');
            target.siblings('.news-item').css('height', 0).on('transitionend', function () {
                if ($(this).height() > 0) { return; }
                $(this).remove();
            });
            return false;
        }

        target.siblings('.open').removeClass('open');
        target.addClass('open');
        var y = target.position().top;
	    /*target.nextAll().each(function () {
	        if ($(this).offset().top > y) {
	            return false;
	        }
	        target = $(this);
	    });*/
        target.siblings('.news-item').css('height', 0).on('transitionend', function () {
            if ($(this).height() > 0) { return; }
            $(this).remove();
        });

        var id = $(this).attr('data-id');
        target.addClass('loading');
        $.get('News/Single/' + id).then(function (result) {
            var item = $(result).addClass('dark popout').insertAfter(target).css({
                position: 'absolute',
                top: y + target.height(),
                left: 0,
                zIndex: 2,
                display: 'block',
                height: 0
            });

            target.removeClass('loading');

            loadImage(item.find('img'));

            setTimeout(function () {
                item.css('height', $('.content', item)[0].offsetHeight + 'px');
            }, 0);

            if (window.gtag) { window.gtag('event', 'single_popout', { 'id': id }); }
        });
        return false;
    });
}