export function loadImage($img, cb) {
    const relayout = img => {
        const m = img.parents('[data-layout="masonry"]')[0];
        m && m.masonry.layout();
    }

    $img.filter(function () {
        var done = $(this).prop('complete');
        if (done) {
            var _this = $(this);
            _this.addClass('loaded');
            setTimeout(function () { relayout(_this) }, 0);
            if (cb) setTimeout(cb, 0);
        }
        return !done;
    }).on('load', function () {
        relayout($(this).addClass('loaded'));
        if (cb) cb();
    });
}